import { computed, ComputedRef, onBeforeUnmount, onMounted, Ref, ref, useContext, watch, WritableComputedRef } from '@nuxtjs/composition-api'
import { PaymentForm, PaymentMethodEnum } from '~/types/Models/Payment'
import useAnalytics from '~/composable/useAnalytics'
import { PAYMENT_VERIFICATION_MAX_TRIES, translateEntityField } from '~/helpers'
import { CartStatusEnum } from '~/types/Models/Cart'
import { paymentFormDefault } from '~/store/state'
import { pushToDataLayer } from '~/helpers/gtm'

export interface PaymentHook {
  getPaymentField(field: keyof PaymentForm): WritableComputedRef<PaymentForm[string]>
  setPaymentCard(cardData: PaymentForm): void
  isPaymentCardValid: ComputedRef<boolean>
  isGtcAccepted: Ref<boolean>
  isPrivacyPolicyAccepted: Ref<boolean>
  isPromoAccepted: Ref<boolean>
  watchPaymentForm(): void
  startPaymentVerification(): void
  isPaid: ComputedRef<boolean>
  isChecking: ComputedRef<boolean>
  paymentTryTimes: Ref<number>
}

function usePayment(): PaymentHook {
  const {
    app,
    app: {
      $accessor,
    },
    $config,
  } = useContext()

  const { dispatchPurchaseEvent, pushAffilaeConversion } = useAnalytics()

  const getPaymentField = (field: keyof PaymentForm) => computed({
    get: () => $accessor.getField(`paymentForm.${field}`),
    set: value => {
      $accessor.UPDATE_FIELD({
        path: `paymentForm.${field}`,
        value,
      })
    },
  })

  function setPaymentCard(cardData: PaymentForm): void {
    $accessor.SET_PAYMENT_CARD(cardData)
  }

  const isPaymentCardValid: ComputedRef<boolean> = computed(() => {
    if ($accessor.paymentForm.type === PaymentMethodEnum.METHOD_IDEAL || $accessor.getIsFullGiftCardPayment) {
      return true
    }
    if ($accessor.paymentForm.type === PaymentMethodEnum.METHOD_BCMC) {
      return !!$accessor.paymentForm.cardNumber && !!$accessor.paymentForm.month &&
        !!$accessor.paymentForm.year && !!$accessor.paymentForm.holderName
    }
    return !!$accessor.paymentForm.cardNumber && !!$accessor.paymentForm.month &&
      !!$accessor.paymentForm.year && !!$accessor.paymentForm.ccv && !!$accessor.paymentForm.holderName
  })

  const isGtcAccepted = ref(false)
  const isPrivacyPolicyAccepted = ref(false)
  const isPromoAccepted = ref(false)

  function watchPaymentForm() {
    const stopWatch = watch(() => [isGtcAccepted.value, isPaymentCardValid.value], ([_isGtcAccepted, _isCardValid]) => {
      const isAllValid = _isGtcAccepted && _isCardValid
      if (isAllValid === $accessor.ui.blockNextStep) {
        $accessor.SET_BLOCK_NEXT_STEP(!isAllValid)
      }
    }, {
      immediate: true,
    })

    const stopWatchType = watch(() => getPaymentField('type').value, (newType) => {
      if (newType === PaymentMethodEnum.METHOD_IDEAL) {
        $accessor.SET_PAYMENT_CARD({
          ...paymentFormDefault(),
          type: newType,
        })
      }
    })

    onBeforeUnmount(() => {
      stopWatch()
      stopWatchType()
    })
  }

  const isPaid = ref(false)
  const isChecking = ref(false)
  const paymentTryTimes = ref(0)
  const doLoopCheck = () => loopCheck()
  let checkTimeout: null | number = null

  function onVerificationError(message?: string): void {
    $accessor.SET_PAYMENT_ERRORS([{
      code: 12345,
      message: message ? app.i18n.t(message) : app.i18n.t('finish.validation_error'),
    }])
    checkTimeout = null
    isChecking.value = false
  }

  function onExpirationError(): void {
    $accessor.SET_PAYMENT_ERRORS([{
      code: 12345,
      message: app.i18n.t('finish.validation_expired'),
    }])
    checkTimeout = null
    isChecking.value = false
  }

  async function loopCheck(): Promise<void> {
    let res: 'CREATED' | 'SUCCEEDED' | 'FAILED'
    paymentTryTimes.value++
    isChecking.value = true

    try {
      res = await $accessor.checkCartPayment()
      if (res === 'CREATED') {
        if (paymentTryTimes.value >= PAYMENT_VERIFICATION_MAX_TRIES) {
          onExpirationError()
        } else {
          checkTimeout = window.setTimeout(() => doLoopCheck(), paymentTryTimes.value * 2000)
        }
      } else {
        if (res === 'FAILED') {
          // TODO: Maybe add message here if MGP / MBB gives us one
          onVerificationError()
          app.router.push(app.localePath({
            name: 'checkout:step-payment',
          }))
        }
        isChecking.value = false
        // Only push to datalayer if marketplace is Fairmoove, to avoid duplicate events (useAnalytics already dispatches this event for other marketplaces).
        if ($accessor.marketplace?.id === 331) {
          try {
            const transactionItems = []
            transactionItems.push({
              product_name: translateEntityField($accessor.service?.name, app.i18n.locale),
              product_id: $accessor.service?.id,
              product_price: ($accessor.cartPreview?.serviceCharge.amount || 0) / 100 / $accessor.getNumberOfNights,
              product_quantity: $accessor.getNumberOfNights,
            })
            if ($accessor.cart) {
              $accessor.cart.options.forEach(cartOption => {
                const option = $accessor.getAvailableOptions.find(o => o.id === cartOption.option)
                const optionCharges = $accessor.cartPreview?.optionCharges
                  .find(oc => oc.optionId === cartOption.option)

                transactionItems.push({
                  product_name: translateEntityField(option!.name, app.i18n.locale),
                  product_id: cartOption.option,
                  product_price: (optionCharges?.chargeWithDiscounts.amount || 0) / 100 / cartOption.quantity,
                  product_quantity: cartOption.quantity,
                })
              })
            }
            pushToDataLayer({
              orderID: $accessor.booking?.id,
              order_amount: ($accessor.booking?.bookingCharge.totalChargeWithDiscountsAndPromocode.amount || 0) / 100,
              orderPaymentType: getPaymentField('type').value,
              transaction_items: transactionItems,
            })
            pushToDataLayer({
              event: 'purchase',
              eventCategory: 'ecommerce',
              eventAction: 'payer',
              eventLabel: '',
            })
          } catch (e) {
            app.$sentry.captureException(e)
            if ($config.environment !== 'production') {
              console.error(e)
            }
          }
        }
      }
    } catch (e) {
      onVerificationError()
      console.log(e) // eslint-disable-line
      app.$sentry.captureException(e, {
        cartId: $accessor.cart?.sessionId,
      } as any)
    }
  }

  function startPaymentVerification() {
    onMounted(async() => {
      if ($accessor.cart?.id) {
        if ([CartStatusEnum.PAID, CartStatusEnum.CLOSED].includes($accessor.cart.status)) {
          isPaid.value = true
        } else {
          await loopCheck()
        }
      }
    })
    onBeforeUnmount(() => {
      if (checkTimeout !== null) {
        window.clearTimeout(checkTimeout)
      }
    })
  }

  watch(() => isPaid.value, (val, prevVal) => {
    if (val && !prevVal) {
      dispatchPurchaseEvent()
      pushAffilaeConversion()
    }
  })

  return {
    getPaymentField,
    setPaymentCard,
    isPaymentCardValid,
    startPaymentVerification,
    isPaid,
    isChecking,
    paymentTryTimes,
    watchPaymentForm,
    isGtcAccepted,
    isPrivacyPolicyAccepted,
    isPromoAccepted,
  }
}

export default usePayment
