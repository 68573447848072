import { ThreeDS2Data } from '~/types/3DS2'

export type PaymentMethod = 'CREDIT_CARD' | 'BCMC' | 'IDEAL' | 'GIFT_CARD'

export enum PaymentMethodEnum {
  METHOD_CARD = 'CREDIT_CARD',
  METHOD_BCMC = 'BCMC',
  METHOD_IDEAL = 'IDEAL',
  METHOD_GIFT_CARD = 'GIFT_CARD',
}

export interface PaymentForm {
  type: PaymentMethod
  cardNumber: string
  month: string
  year: string
  ccv: string
  holderName: string

  [key: string]: string | PaymentMethod
}

export interface PaymentFormWith3DSData extends PaymentForm {
  threeDS2Data: ThreeDS2Data

  [key: string]: any
}

export interface GiftCardPaymentForm {
  type: PaymentMethod
  isFullGiftCardPayment: boolean
}

export enum DiscountTypeEnum {
  DISCOUNT_PROMOCODE = 'PROMOCODE',
  DISCOUNT_GIFTCARDS = 'GIFTCARDS',
}

export type DiscountType = DiscountTypeEnum.DISCOUNT_PROMOCODE | DiscountTypeEnum.DISCOUNT_GIFTCARDS | null


export interface CreatePaymentPayload {
  type: 'credit_card',
  cartId: string
  screenWidth: string
  screenHeight: string
  colorDepth: string
  userAgent: string
  javaEnabled: boolean,
  javascriptEnabled: boolean
  language: string
  timeZoneOffset: string,
  isFullGiftCard: boolean,
  trkData: {
    affilaeTrackingId?: null | string
    originName?: null | string
  }
}

export type CreatePaymentResponse = {
  cardRegistration: {
    id: string,
    preregistrationData: string
    cardRegistrationUrl: string
    accessKey: string
  },
  paymentUuid: string
  returnUrl: string
  mangopayUserId: string
  type: 'credit_card'
} | {
  type: 'giftcard'
}

export interface CheckPaymentTaskStatusResponse {
  status: 'PENDING' | 'STARTED' | 'SUCCESS' | 'FAILURE' | 'RETRY' | 'REVOKED'
  payinStatus: string | null
  secureModeNeeded: boolean | null
  returnUrl: string | null
  secureModeRedirectUrl: string | null
}