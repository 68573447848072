import { Logger } from '~/helpers/logger'
import { CreatePaymentPayload, CreatePaymentResponse, CheckPaymentTaskStatusResponse } from "~/types/Models"
import { isAxiosError, isExpectableError } from '~/helpers/api'
import { useContext } from '@nuxtjs/composition-api'
import { CartError } from '~/types/Errors'

function get3DS2Fields() {
  const screenWidth = window && window.screen ? window.screen.width.toString() : ''
  const screenHeight = window && window.screen ? window.screen.height.toString() : ''
  const colorDepth = window && window.screen ? window.screen.colorDepth.toString() : ''
  const userAgent = window && window.navigator ? window.navigator.userAgent : ''
  const javaEnabled = window && window.navigator ? navigator.javaEnabled() : false
  const javascriptEnabled = true

  let language = ''
  if (window && window.navigator) {
    language = window.navigator.language
      ? window.navigator.language
      : (window.navigator as Navigator & { browserLanguage: string }).browserLanguage // Else is for IE <+ 10
  }

  const d = new Date()
  const timeZoneOffset = d.getTimezoneOffset().toString()

  return {
    screenWidth,
    screenHeight,
    colorDepth,
    userAgent,
    javaEnabled,
    javascriptEnabled,
    language,
    timeZoneOffset,
  }
}

function toURLEncodedString(data: string | string[][] | Record<string, string> | URLSearchParams | undefined) {
  const params = new URLSearchParams(data)
  return params.toString()
}

async function processPossibleError(mbbUrl: string, cardRegistrationData: string) {
  if (cardRegistrationData.startsWith('errorCode')) {
    // If it's an error
    const error_code = cardRegistrationData.substring(10)
    const errorMessage = await fetch(`${mbbUrl}/mangopay/error/${error_code}/`)
    throw new Error(await errorMessage.text())
  } else {
    // If is not an error:
    return cardRegistrationData
  }
}

async function tokenizeCard(cardRegistrationUrl: string, encodedData: string) {
  const data = await fetch(cardRegistrationUrl, {
    method: 'POST',
    body: encodedData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    mode: 'cors',
  })

  return data.text()
}

export default function useMbbPayment() {
  const { app: {
    $accessor,
    $cookies,
    $api,
    i18n,
    $sentry,
    router,
    localePath,
  }, $config } = useContext()

  const logger = new Logger()

  function checkTaskStatus(
    { status, payinStatus, secureModeNeeded, secureModeRedirectUrl, returnUrl }: CheckPaymentTaskStatusResponse,
    cartUuid: string,
  ) {
    if (
      status == 'SUCCESS' &&
      payinStatus === 'CREATED' &&
      secureModeNeeded === true &&
      secureModeRedirectUrl
    ) {
      logger.debug('Redirecting to 3dsecure url:', secureModeRedirectUrl)
      window.location.replace(secureModeRedirectUrl)
    } else if (status === 'SUCCESS' && payinStatus === 'SUCCEEDED') {
      router.push(localePath({
        name: 'checkout:finish',
        params: { cartId: cartUuid },
      }))
    } else if (status === 'SUCCESS' && payinStatus === 'FAILED') {
      $accessor.SET_PAYMENT_ERRORS([{
        code: 0,
        message: i18n.t('errors.cart_generic_error'),
      }])
    } else {
      $accessor.SET_PAYMENT_ERRORS([{
        code: 0,
        message: i18n.t('errors.cart_generic_error'),
      }])
      $sentry.captureEvent({
        message: 'A status check was not successful',
        extra: {
          status, payinStatus, secureModeNeeded, secureModeRedirectUrl, returnUrl, cartUuid,
        },
      })
    }
    $accessor.DEC_CART_LOADING()
  }

  async function checkPaymentStatus(cartUuid: string) {
    const taskStatus: CheckPaymentTaskStatusResponse = await $api.post(`carts/${cartUuid}/check-payment-task-status`)

    const hasWorkingStatus = ['SUCCESS', 'FAILURE'].includes(taskStatus.status)

    if (!hasWorkingStatus) {
      setTimeout(() => {
        checkPaymentStatus(cartUuid)
      }, 1000)
    } else {
      checkTaskStatus(taskStatus, cartUuid)
    }
  }

  async function pay() {
    $accessor.SET_CART_ERRORS([])
    $accessor.SET_CART_PREVIEW_ERRORS([])
    $accessor.SET_PAYMENT_ERRORS([])

    try {
      $accessor.INC_CART_LOADING()
      // If the user added a message to the host, update the cart a last time before paying
      if ($accessor.cart?.hostMessage) {
        await $accessor.updateCart()
      }

      if (!$accessor.cart?.sessionId) {
        throw new Error('No cart session id found, cannot pay')
      }
      const cartUuid = $accessor.cart.sessionId

      const isFullGiftCard = $accessor.getIsFullGiftCardPayment

      // check for https://sentry.unicstay.com/organizations/unicstay/issues/257/?project=5&query=threeDS2Data&statsPeriod=14d
      if (isFullGiftCard && $accessor.cart && $accessor.cart.giftCards.length === 0) {
        throw new Error('getIsFullGiftCardPayment returns true while no giftCards in cart')
      }

      const payload: CreatePaymentPayload = {
        ...get3DS2Fields(),
        type: 'credit_card',
        cartId: $accessor.cart.sessionId,
        isFullGiftCard,
        trkData: {},
      }

      if ($cookies.get<string>('ae_id')) {
        payload.trkData.affilaeTrackingId = $cookies.get<string>('ae_id')
      }
      if ($cookies.get<string>('origin_name')) {
        payload.trkData.originName = $cookies.get<string>('origin_name')
      }

      const response: CreatePaymentResponse = await $api.post(`carts/${cartUuid}/create-payment`, { ...payload })

      // If the payment is full giftcard, just go to the finish now, no need to do the rest
      if (response.type === 'giftcard') {
        router.push(localePath({
          name: 'checkout:finish',
          params: { cartId: cartUuid },
        }))
        return
      }

      const {
        cardRegistration: {
          accessKey,
          preregistrationData,
          cardRegistrationUrl,
          id,
        },
        paymentUuid,
        returnUrl,
        mangopayUserId,
      } = response

      const cardExpirationMonth = $accessor.paymentForm.month
      const cardExpirationYear = $accessor.paymentForm.year
      const cardExpirationDate = `${cardExpirationMonth}${cardExpirationYear}`

      const cardNumber = $accessor.paymentForm.cardNumber
      const cardCvx = $accessor.paymentForm.ccv

      const encodedData = toURLEncodedString({
        accessKeyRef: accessKey,
        data: preregistrationData,
        cardNumber,
        cardExpirationDate,
        cardCvx,
      })

      const cardRegistrationData = await tokenizeCard(cardRegistrationUrl, encodedData)
      await processPossibleError($config.mbbUrl, cardRegistrationData)

      await $api.post(`carts/${cartUuid}/finish-payment`, {
        cardId: id,
        cardRegistrationData,
        paymentUuid,
        returnUrl,
        mangopayUserPk: mangopayUserId,
      })

      setTimeout(() => {
        checkPaymentStatus(cartUuid)
      }, 1000)
    } catch (e: any) {
      logger.debug('Error during cart payment', e)
      if (isAxiosError(e) && isExpectableError(e)) {
        const {
          code,
          message,
        } = e.response.data as CartError

        $accessor._handlePaymentErrors(code)
        $accessor.SET_PAYMENT_ERRORS([{
          code,
          message,
        }])
        $sentry.captureException(e, { extra: { code, message } })
      } else {
        $accessor.SET_PAYMENT_ERRORS([{
          code: 0,
          message: i18n.t('errors.cart_generic_error'),
        }])
        $sentry.captureException(e)
      }
    }
  }

  return {
    pay,
  }
}
