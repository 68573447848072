import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from './components/nuxt-error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_b683391c from 'nuxt_plugin_sentryserver_b683391c' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_3f04f1ea from 'nuxt_plugin_sentryclient_3f04f1ea' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_plugin_6fa320ff from 'nuxt_plugin_plugin_6fa320ff' // Source: ./composition-api/plugin.js (mode: 'all')
import nuxt_plugin_featuretoggles_211eae48 from 'nuxt_plugin_featuretoggles_211eae48' // Source: ./feature-toggles.js (mode: 'all')
import nuxt_plugin_toast_6e8b0346 from 'nuxt_plugin_toast_6e8b0346' // Source: ./toast.js (mode: 'client')
import nuxt_plugin_gtm_71b0e7f8 from 'nuxt_plugin_gtm_71b0e7f8' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_dayjsplugin_7e56076f from 'nuxt_plugin_dayjsplugin_7e56076f' // Source: ./dayjs-plugin.js (mode: 'all')
import nuxt_plugin_webfontloader_378de9ae from 'nuxt_plugin_webfontloader_378de9ae' // Source: ./webfontloader.js (mode: 'client')
import nuxt_plugin_nuxtvuexroutersync_5df65e96 from 'nuxt_plugin_nuxtvuexroutersync_5df65e96' // Source: ./nuxt-vuex-router-sync.js (mode: 'all')
import nuxt_plugin_pluginutils_23ec0e4d from 'nuxt_plugin_pluginutils_23ec0e4d' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_e1bb8750 from 'nuxt_plugin_pluginrouting_e1bb8750' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_63a063d2 from 'nuxt_plugin_pluginmain_63a063d2' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_7476b556 from 'nuxt_plugin_cookieuniversalnuxt_7476b556' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_axios_06eb9ca4 from 'nuxt_plugin_axios_06eb9ca4' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_nuxttypedvuex_3bb26105 from 'nuxt_plugin_nuxttypedvuex_3bb26105' // Source: ./nuxt-typed-vuex.js (mode: 'all')
import nuxt_plugin_router_49088fc1 from 'nuxt_plugin_router_49088fc1' // Source: ./router.js (mode: 'all')
import nuxt_plugin_deviceplugin_7237898e from 'nuxt_plugin_deviceplugin_7237898e' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_api_7852046e from 'nuxt_plugin_api_7852046e' // Source: ../plugins/api.ts (mode: 'all')
import nuxt_plugin_dayjs_66ce609e from 'nuxt_plugin_dayjs_66ce609e' // Source: ../plugins/dayjs.ts (mode: 'all')
import nuxt_plugin_dinero_63a8c19e from 'nuxt_plugin_dinero_63a8c19e' // Source: ../plugins/dinero.ts (mode: 'all')
import nuxt_plugin_gtag_0a2caf78 from 'nuxt_plugin_gtag_0a2caf78' // Source: ../plugins/gtag.ts (mode: 'all')
import nuxt_plugin_gtm_63637dfa from 'nuxt_plugin_gtm_63637dfa' // Source: ../plugins/gtm.ts (mode: 'client')
import nuxt_plugin_i18n_1fba4fce from 'nuxt_plugin_i18n_1fba4fce' // Source: ../plugins/i18n.ts (mode: 'all')
import nuxt_plugin_lodash_5d4c7cc2 from 'nuxt_plugin_lodash_5d4c7cc2' // Source: ../plugins/lodash.ts (mode: 'all')
import nuxt_plugin_luxon_73388096 from 'nuxt_plugin_luxon_73388096' // Source: ../plugins/luxon.ts (mode: 'all')
import nuxt_plugin_sweetcase_209bee4d from 'nuxt_plugin_sweetcase_209bee4d' // Source: ../plugins/sweetcase.js (mode: 'all')
import nuxt_plugin_touch_7b8535f8 from 'nuxt_plugin_touch_7b8535f8' // Source: ../plugins/touch.ts (mode: 'all')
import nuxt_plugin_veevalidate_1a0c172c from 'nuxt_plugin_veevalidate_1a0c172c' // Source: ../plugins/vee-validate.ts (mode: 'all')
import nuxt_plugin_vmask_3c04b27e from 'nuxt_plugin_vmask_3c04b27e' // Source: ../plugins/v-mask.js (mode: 'all')
import nuxt_plugin_vuemq_7319a416 from 'nuxt_plugin_vuemq_7319a416' // Source: ../plugins/vue-mq.js (mode: 'all')
import nuxt_plugin_vuexpersistedstate_072cbd0a from 'nuxt_plugin_vuexpersistedstate_072cbd0a' // Source: ../plugins/vuex-persistedstate.ts (mode: 'all')
import nuxt_plugin_vlazyimage_391f95a8 from 'nuxt_plugin_vlazyimage_391f95a8' // Source: ../plugins/v-lazy-image.js (mode: 'client')
import nuxt_plugin_vueportal_5154fc04 from 'nuxt_plugin_vueportal_5154fc04' // Source: ../plugins/vue-portal.ts (mode: 'client')
import nuxt_plugin_meta_aa2b88a6 from 'nuxt_plugin_meta_aa2b88a6' // Source: ./composition-api/meta.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Unic Stay - Book Fast Funnel","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""}],"bodyAttrs":{"class":["Site"],"id":"app"},"link":[{"rel":"preconnect","href":"https:\u002F\u002Fwww.google-analytics.com\u002F"}],"style":[],"script":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_b683391c === 'function') {
    await nuxt_plugin_sentryserver_b683391c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_3f04f1ea === 'function') {
    await nuxt_plugin_sentryclient_3f04f1ea(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_6fa320ff === 'function') {
    await nuxt_plugin_plugin_6fa320ff(app.context, inject)
  }

  if (typeof nuxt_plugin_featuretoggles_211eae48 === 'function') {
    await nuxt_plugin_featuretoggles_211eae48(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_toast_6e8b0346 === 'function') {
    await nuxt_plugin_toast_6e8b0346(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_71b0e7f8 === 'function') {
    await nuxt_plugin_gtm_71b0e7f8(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjsplugin_7e56076f === 'function') {
    await nuxt_plugin_dayjsplugin_7e56076f(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_webfontloader_378de9ae === 'function') {
    await nuxt_plugin_webfontloader_378de9ae(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtvuexroutersync_5df65e96 === 'function') {
    await nuxt_plugin_nuxtvuexroutersync_5df65e96(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_23ec0e4d === 'function') {
    await nuxt_plugin_pluginutils_23ec0e4d(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_e1bb8750 === 'function') {
    await nuxt_plugin_pluginrouting_e1bb8750(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_63a063d2 === 'function') {
    await nuxt_plugin_pluginmain_63a063d2(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_7476b556 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_7476b556(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_06eb9ca4 === 'function') {
    await nuxt_plugin_axios_06eb9ca4(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxttypedvuex_3bb26105 === 'function') {
    await nuxt_plugin_nuxttypedvuex_3bb26105(app.context, inject)
  }

  if (typeof nuxt_plugin_router_49088fc1 === 'function') {
    await nuxt_plugin_router_49088fc1(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_7237898e === 'function') {
    await nuxt_plugin_deviceplugin_7237898e(app.context, inject)
  }

  if (typeof nuxt_plugin_api_7852046e === 'function') {
    await nuxt_plugin_api_7852046e(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjs_66ce609e === 'function') {
    await nuxt_plugin_dayjs_66ce609e(app.context, inject)
  }

  if (typeof nuxt_plugin_dinero_63a8c19e === 'function') {
    await nuxt_plugin_dinero_63a8c19e(app.context, inject)
  }

  if (typeof nuxt_plugin_gtag_0a2caf78 === 'function') {
    await nuxt_plugin_gtag_0a2caf78(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gtm_63637dfa === 'function') {
    await nuxt_plugin_gtm_63637dfa(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_1fba4fce === 'function') {
    await nuxt_plugin_i18n_1fba4fce(app.context, inject)
  }

  if (typeof nuxt_plugin_lodash_5d4c7cc2 === 'function') {
    await nuxt_plugin_lodash_5d4c7cc2(app.context, inject)
  }

  if (typeof nuxt_plugin_luxon_73388096 === 'function') {
    await nuxt_plugin_luxon_73388096(app.context, inject)
  }

  if (typeof nuxt_plugin_sweetcase_209bee4d === 'function') {
    await nuxt_plugin_sweetcase_209bee4d(app.context, inject)
  }

  if (typeof nuxt_plugin_touch_7b8535f8 === 'function') {
    await nuxt_plugin_touch_7b8535f8(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_1a0c172c === 'function') {
    await nuxt_plugin_veevalidate_1a0c172c(app.context, inject)
  }

  if (typeof nuxt_plugin_vmask_3c04b27e === 'function') {
    await nuxt_plugin_vmask_3c04b27e(app.context, inject)
  }

  if (typeof nuxt_plugin_vuemq_7319a416 === 'function') {
    await nuxt_plugin_vuemq_7319a416(app.context, inject)
  }

  if (typeof nuxt_plugin_vuexpersistedstate_072cbd0a === 'function') {
    await nuxt_plugin_vuexpersistedstate_072cbd0a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vlazyimage_391f95a8 === 'function') {
    await nuxt_plugin_vlazyimage_391f95a8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueportal_5154fc04 === 'function') {
    await nuxt_plugin_vueportal_5154fc04(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_aa2b88a6 === 'function') {
    await nuxt_plugin_meta_aa2b88a6(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
