import { Guest } from '~/types/Models/Guest'
import { nanoid } from 'nanoid'

export const generateId = (len: number): string => {
  function dec2hex(dec: number) {
    return ('0' + dec.toString(16)).substr(-2)
  }

  const arr = new Uint8Array((len || 40) / 2)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex)
    .join('')
}

/**
 * Create a generator function returning an iterator to a specified range of numbers.
 * @param start
 * @param end
 * @param interval
 */
export function * range(start: number, end: number, interval = 1): IterableIterator<number> {
  for (let i = start; i < end; i += interval) {
    yield i
  }
}

export * from './money'
export * from './locale'
export * from './age'
export * from './dateTime'

export const getEmptyGuest: (idx: number) => Guest = (idx) => ({
  firstName: `Prénom-${nanoid(12)}`,
  lastName: `Nom-${nanoid(12)}`,
  dateOfBirth: '',
  idx,
})

export const PAYMENT_VERIFICATION_MAX_TRIES = 10
