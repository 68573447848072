






































































































































import { defineComponent, useContext, useMeta, ref, computed } from '@nuxtjs/composition-api'
import usePayment from '~/composable/usePayment'
import useSteps from '~/composable/useSteps'
import useCart from '~/composable/useCart'
import useMbbPayment from '~/composable/useMbbPayment'
import { PaymentMethod, PaymentMethodEnum } from '~/types/Models/Payment'
import PaymentCardForm from '~/components/payment/PaymentCardForm.vue'
import HostMessageForm from '~/components/payment/HostMessageForm.vue'
import ReassuranceCard from '~/components/content/ReassuranceCard.vue'
import CreditCardIcon from '~/components/icons/CreditCardIcon.vue'
import ReassuranceBlock from '~/components/content/ReassuranceBlock.vue'
import AncvModal from '~/components/payment/AncvModal.vue'
import useMarketplace from '~/composable/useMarketplace'

export default defineComponent({
  name: 'Steps',
  components: {
    AncvModal,
    CreditCardIcon,
    HostMessageForm,
    PaymentCardForm,
    ReassuranceCard,
    ReassuranceBlock,
  },
  setup() {
    const { $config, app: { $accessor, i18n, $translateEntityField, $featureToggle, $sentry } } = useContext()
    const { hasOptionsStep } = useSteps()
    const { isFullGiftCardPayment, getCartField } = useCart()
    const {
      getPaymentField,
      setPaymentCard,
      isPaymentCardValid,
      isGtcAccepted,
      isPrivacyPolicyAccepted,
      watchPaymentForm,
    } = usePayment()
    const { isABCD, isFairmoove } = useMarketplace()

    watchPaymentForm()

    const isAncvModalActive = ref(false)

    const isAncvEnabled = computed(() =>
      (isABCD.value &&
      i18n.locale === 'fr') &&
      $featureToggle.toggles.ancv as boolean,
      // TODO: Uncomment (and get isUSCOM from useMarketplace) to activate for uscom
      // ((isABCD.value || isUSCOM.value) &&
      // i18n.locale === 'fr') &&
      // $featureToggle.toggles.ancv as boolean,
    )

    async function onPromocodeUpdate(code: string | null) {
      await $accessor.updatePromocode(code)
    }

    // eslint-disable-next-line no-unused-vars
    function onPaymentTypeChange(paymentMethod: PaymentMethod, isActive: boolean) {
      getPaymentField('type').value = paymentMethod
    }

    function handleTermsClick(event: MouseEvent): void {
      if ((event.target as HTMLElement).tagName === 'SPAN') {
        isGtcAccepted.value = !isGtcAccepted.value
      }
    }

    function handlePolicyClick(event: MouseEvent): void {
      if ((event.target as HTMLElement).tagName === 'SPAN') {
        isPrivacyPolicyAccepted.value = !isPrivacyPolicyAccepted.value
      }
    }

    function handleIsDiscountOfferAcceptedClick(): void {
      getCartField('isDiscountOfferAccepted').value = !getCartField('isDiscountOfferAccepted').value
    }

    async function openConditionsPdf() {
      try {
        const cartId = $accessor.cart?.sessionId
        const url = `${$config.axios.baseURL}/carts/${cartId}/policies/download`

        const a = document.createElement('a')
        a.target = '_blank'
        a.href = url
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      } catch (e) {
        $sentry.captureException('Failed to open conditions PDF')
      }
    }

    useMeta({
      title: `${$accessor.marketplace
        ? $translateEntityField($accessor.marketplace.name)
        : 'Abracadaroom'} | ${i18n.t('steps.payment')}`,
    })

    return {
      // isPrivacyPolicyAccepted,
      isAncvEnabled,
      isFairmoove,
      getCartField,
      getPaymentField,
      handleIsDiscountOfferAcceptedClick,
      handlePolicyClick,
      handleTermsClick,
      hasOptionsStep,
      isAncvModalActive,
      isFullGiftCardPayment,
      isGtcAccepted,
      isPaymentCardValid,
      onPaymentTypeChange,
      onPromocodeUpdate,
      PaymentMethodEnum,
      setPaymentCard,
      openConditionsPdf,
    }
  },
  head() {
    return {}
  },
})
